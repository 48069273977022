import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Box, Grid, Avatar, Chip } from '@mui/material';
import { motion } from 'framer-motion';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import ChurchIcon from '@mui/icons-material/Church';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import WorkIcon from '@mui/icons-material/Work';
import SportsIcon from '@mui/icons-material/Sports';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import FlightIcon from '@mui/icons-material/Flight';
import PoolIcon from '@mui/icons-material/Pool';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';

const About = () => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .burrito-cursor {
        width: 24px;
        height: 24px;
        position: fixed;
        pointer-events: none;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        transition: all 0.1s ease;
        transform: translate(-50%, -50%);
      }
      
      .burrito-cursor:hover {
        transform: translate(-50%, -50%) scale(1.2);
      }
    `;
    document.head.appendChild(style);

    const cursor = document.createElement('div');
    cursor.className = 'burrito-cursor';
    cursor.innerHTML = '🌯';
    document.body.appendChild(cursor);

    const moveCursor = (e) => {
      cursor.style.left = e.clientX + 'px';
      cursor.style.top = e.clientY + 'px';
    };

    document.addEventListener('mousemove', moveCursor);
    document.body.style.cursor = 'none';

    return () => {
      document.head.removeChild(style);
      document.body.removeChild(cursor);
      document.removeEventListener('mousemove', moveCursor);
      document.body.style.cursor = 'default';
    };
  }, []);

  const photos = [
    '/images/IMG_0701.jpeg',
    '/images/IMG_1770.jpeg',
    '/images/IMG_2597.jpeg',
    '/images/IMG_2931.jpeg',
    '/images/IMG_3259.jpeg'
  ];

  const photoStyles = {
    'IMG_0701': { objectPosition: 'center 65%' },
    'IMG_1770': { 
      width: '200%',
      height: '200%',
      objectPosition: '-30% -75%',
      transform: 'translate(-25%, 0)'
    },
    'IMG_2597': { objectPosition: 'center center' },
    'IMG_2931': { objectPosition: 'center center' },
    'IMG_3259': { objectPosition: 'center center' }
  };

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [isHovered]);

  const passions = [
    {
      icon: <ChurchIcon sx={{ fontSize: 30, color: 'primary.main', mb: 1 }} />,
      title: 'Faith & Values',
      description: 'My passion for God, generosity, and integrity is the foundation of everything I do. I strive to live a life that honors my faith through service, making an impact through generosity, and always upholding integrity in both personal and professional interactions.'
    },
    {
      icon: <FamilyRestroomIcon sx={{ fontSize: 30, color: 'primary.main', mb: 1 }} />,
      title: 'Family',
      description: 'I\'m deeply passionate about my family, which is my greatest source of joy and inspiration. I\'m excited to welcome our son, Ezra, into the world, and I\'m grateful for the love and support of my wife, Dejai, as we embark on this journey together. Our dog, Charley, completes our family, offering us unwavering companionship.'
    },
    {
      icon: <WorkIcon sx={{ fontSize: 30, color: 'primary.main', mb: 1 }} />,
      title: 'Sales & Technology',
      description: 'My passion for sales and technology has been the driving force behind my career. I am deeply committed to unlocking massive value for companies of all sizes, helping them embrace innovative technologies like Weaviate to drive their growth.'
    }
  ];

  const hobbies = [
    {
      icon: <DirectionsRunIcon />,
      name: 'Running',
      color: 'primary.main'
    },
    {
      icon: <GolfCourseIcon />,
      name: 'Golfing',
      color: 'success.main'
    },
    {
      icon: <SportsTennisIcon />,
      name: 'Pickleball',
      color: 'warning.main'
    },
    {
      icon: <DirectionsBikeIcon />,
      name: 'Cycling',
      color: 'error.dark'
    },
    {
      icon: <PoolIcon />,
      name: 'Swimming',
      color: 'info.dark'
    },
    {
      icon: <SportsEsportsIcon />,
      name: 'Video Games',
      color: 'error.main'
    },
    {
      icon: <MenuBookIcon />,
      name: 'Reading',
      color: 'info.main'
    },
    {
      icon: <MusicNoteIcon />,
      name: 'Music',
      color: 'secondary.dark'
    },
    {
      icon: <FlightIcon />,
      name: 'Travel',
      color: 'primary.dark'
    },
    {
      icon: <SmartToyIcon />,
      name: 'Making AI Apps',
      color: 'secondary.main'
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0
    }
  };

  return (
    <Container maxWidth="md">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <motion.div variants={itemVariants}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    sx={{ 
                      position: 'relative', 
                      width: 200, 
                      height: 200,
                      mb: 2,
                      borderRadius: '50%',
                      overflow: 'hidden'
                    }}
                  >
                    {photos.map((photo, index) => {
                      const photoId = photo.split('/').pop().split('.')[0];
                      const style = photoStyles[photoId] || {};
                      
                      return (
                        <Avatar
                          key={photo}
                          sx={{
                            width: '100%',
                            height: '100%',
                            border: '4px solid',
                            borderColor: 'primary.main',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            transition: 'opacity 0.5s ease-in-out',
                            opacity: currentPhotoIndex === index ? 1 : 0,
                            '& .MuiAvatar-img': {
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                              ...style
                            }
                          }}
                          alt={`Aaron Trott Photo ${index + 1}`}
                          src={photo}
                        />
                      );
                    })}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                    <a href="https://www.linkedin.com/in/aarontrott/" target="_blank" rel="noopener noreferrer">
                      <LinkedInIcon sx={{ 
                        fontSize: 30, 
                        color: 'primary.main', 
                        cursor: 'pointer',
                        '&:hover': {
                          color: 'primary.dark',
                          transform: 'translateY(-2px)',
                          transition: 'all 0.2s'
                        }
                      }} />
                    </a>
                    <a href="https://github.com/neckolis/neckolis/blob/main/README.md" target="_blank" rel="noopener noreferrer">
                      <GitHubIcon sx={{ 
                        fontSize: 30, 
                        color: 'primary.main', 
                        cursor: 'pointer',
                        '&:hover': {
                          color: 'primary.dark',
                          transform: 'translateY(-2px)',
                          transition: 'all 0.2s'
                        }
                      }} />
                    </a>
                  </Box>
                </Box>
              </motion.div>
            </Grid>

            <Grid item xs={12} md={8}>
              <motion.div variants={itemVariants}>
                <Typography variant="h4" component="h1" gutterBottom color="primary">
                  About Me
                </Typography>
                <Typography variant="body1" paragraph>
                  My life is driven by three core passions: my faith, my family, and my work in sales and technology. These values guide me in building relationships based on trust, empathy, and a commitment to making the world a better place.
                </Typography>

                {passions.map((passion, index) => (
                  <motion.div
                    key={passion.title}
                    variants={itemVariants}
                    transition={{ delay: index * 0.2 }}
                  >
                    <Box sx={{ mt: 4 }}>
                      {passion.icon}
                      <Typography variant="h6" gutterBottom>
                        {passion.title}
                      </Typography>
                      <Typography variant="body1" paragraph>
                        {passion.description}
                      </Typography>
                    </Box>
                  </motion.div>
                ))}

                <Box sx={{ mt: 6 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <SportsIcon sx={{ fontSize: 30, color: 'primary.main', mr: 1 }} />
                    <Typography variant="h6">
                      Hobbies & Interests
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {hobbies.map((hobby, index) => (
                      <motion.div
                        key={hobby.name}
                        variants={{
                          hidden: { opacity: 0, scale: 0.8 },
                          visible: {
                            opacity: 1,
                            scale: 1,
                            transition: { delay: index * 0.1 }
                          }
                        }}
                      >
                        <Chip
                          icon={hobby.icon}
                          label={hobby.name}
                          sx={{ 
                            fontSize: '1rem',
                            '& .MuiChip-icon': {
                              color: hobby.color
                            }
                          }}
                          variant="outlined"
                        />
                      </motion.div>
                    ))}
                  </Box>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Paper>
      </motion.div>
    </Container>
  );
};

export default About;
