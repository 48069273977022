import React, { useState } from 'react';
import { Container, Typography, Grid, Paper, Box, Tabs, Tab } from '@mui/material';
import { motion } from 'framer-motion';
import SearchIcon from '@mui/icons-material/Search';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SpeedIcon from '@mui/icons-material/Speed';
import CloudIcon from '@mui/icons-material/Cloud';
import CompareIcon from '@mui/icons-material/Compare';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BusinessIcon from '@mui/icons-material/Business';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import StorageIcon from '@mui/icons-material/Storage';

const Overview = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const features = [
    {
      icon: <SearchIcon fontSize="large" />,
      title: 'Hybrid Search',
      description: 'Combines traditional keyword search (BM25) with vector search for precise and contextual results. Supports text, images, audio, and videos.'
    },
    {
      icon: <AutoAwesomeIcon fontSize="large" />,
      title: 'RAG and AI Integration',
      description: 'Out-of-the-box Retrieval-Augmented Generation (RAG) with support for over 20 ML models, enabling seamless interaction with proprietary data.'
    },
    {
      icon: <SpeedIcon fontSize="large" />,
      title: 'Efficiency and Scalability',
      description: 'Advanced filtering, multi-tenancy, and vector index compression with zero-downtime backups for enterprise-grade performance.'
    },
    {
      icon: <CloudIcon fontSize="large" />,
      title: 'Deployment Options',
      description: 'Available as serverless cloud, enterprise cloud, or bring-your-own-cloud solutions with built-in developer tools.'
    }
  ];

  const competition = [
    {
      icon: <StorageIcon fontSize="large" />,
      title: 'ClickHouse',
      description: 'Focuses on real-time analytics with columnar storage. While excellent for OLAP, lacks optimization for AI-driven applications and vector search capabilities essential for modern AI.'
    },
    {
      icon: <CloudIcon fontSize="large" />,
      title: 'Pinecone',
      description: 'Managed vector database with easy deployment, but lacks Weaviate\'s flexibility, open-source nature, and advanced multimodal search capabilities across text, images, and audio.'
    },
    {
      icon: <StorageIcon fontSize="large" />,
      title: 'MongoDB',
      description: 'Excellent general-purpose NoSQL database, but not optimized for high-performance vector search and semantic retrieval needed in modern AI applications.'
    },
    {
      icon: <CompareIcon fontSize="large" />,
      title: 'Milvus',
      description: 'Strong in similarity search, but Weaviate differentiates with hybrid search combining vectors and keywords, plus built-in RAG support for enhanced AI integration.'
    },
    {
      icon: <StorageIcon fontSize="large" />,
      title: 'Postgres',
      description: 'Traditional relational database with PGVector extension, but lacks native AI optimizations and scalability features that Weaviate offers for vector search.'
    },
    {
      icon: <SpeedIcon fontSize="large" />,
      title: 'Redis',
      description: 'Fast in-memory store with vector modules, but not optimized for large-scale AI applications and missing advanced features like hybrid and multimodal search.'
    },
    {
      icon: <CloudIcon fontSize="large" />,
      title: 'AWS',
      description: 'Offers vector search through OpenSearch and Kendra, but Weaviate provides more specialized, open-source solution with greater flexibility for AI applications.'
    },
    {
      icon: <BusinessIcon fontSize="large" />,
      title: 'Databricks',
      description: 'Strong in data analytics and ML pipelines, but Weaviate is purpose-built for vector search and optimized for AI applications requiring fast unstructured data retrieval.'
    },
    {
      icon: <SearchIcon fontSize="large" />,
      title: 'Elastic',
      description: 'Excels at full-text search but primarily keyword-focused. Lacks advanced AI capabilities like hybrid search, multimodal search, and ML model integrations.'
    }
  ];

  const opportunity = [
    {
      icon: <TrendingUpIcon fontSize="large" />,
      title: 'Expanding Market',
      description: 'Rapidly growing vector database market driven by demand for AI-native applications like semantic search, chatbots, and recommendation systems.'
    },
    {
      icon: <RocketLaunchIcon fontSize="large" />,
      title: 'AI-Native Startups',
      description: 'Well-positioned to serve startups building products from the ground up with AI-driven functionalities.'
    },
    {
      icon: <BusinessIcon fontSize="large" />,
      title: 'Enterprise Adoption',
      description: 'Large enterprises increasingly adopt vector search to enhance workflows, augment traditional databases, and optimize customer experiences.'
    },
    {
      icon: <AccountTreeIcon fontSize="large" />,
      title: 'Vertical AI Solutions',
      description: 'Supporting AI agents for vertical markets such as healthcare, finance, and retail, aligning with specialized AI adoption trends.'
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0
    }
  };

  const TabPanel = ({ children, value, index }) => (
    <Box role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </Box>
  );

  const renderGrid = (items) => (
    <Grid container spacing={4}>
      {items.map((item, index) => (
        <Grid item xs={12} md={activeTab === 1 ? 4 : 3} key={index}>
          <motion.div variants={itemVariants}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: 6
                }
              }}
            >
              <Box sx={{ color: 'primary.main', mb: 2 }}>
                {item.icon}
              </Box>
              <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
                {item.title}
              </Typography>
              <Typography color="text.secondary" sx={{ flexGrow: 1 }}>
                {item.description}
              </Typography>
            </Paper>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Container maxWidth="lg">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Typography variant="h3" component="h1" gutterBottom color="primary">
          Overview
        </Typography>
        
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label="Features" />
            <Tab label="Competition" />
            <Tab label="Opportunity" />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          {renderGrid(features)}
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          {renderGrid(competition)}
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          {renderGrid(opportunity)}
        </TabPanel>
      </motion.div>
    </Container>
  );
};

export default Overview;
