import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Home = () => {
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0
    }
  };

  return (
    <Container maxWidth="lg">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Box
          sx={{
            minHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            gap: 4
          }}
        >
          <motion.div variants={itemVariants}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: 'primary.main'
              }}
            >
              Welcome
            </Typography>
          </motion.div>

          <motion.div variants={itemVariants}>
            <Typography variant="h6" sx={{ mb: 2, color: 'text.secondary' }}>
              Explore the evolution of databases and learn why Weaviate is the top choice for AI-native startups and enterprises.
            </Typography>
          </motion.div>

          <motion.div variants={itemVariants}>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center' }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate('/history')}
                sx={{ minWidth: '200px' }}
              >
                Explore Database History
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => navigate('/overview')}
                sx={{ minWidth: '200px' }}
              >
                Learn About Weaviate
              </Button>
            </Box>
          </motion.div>
        </Box>
      </motion.div>
    </Container>
  );
};

export default Home;
