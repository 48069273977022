import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent
} from '@mui/lab';
import { motion } from 'framer-motion';

const History = () => {
  const timelineItems = [
    {
      year: '~Antiquity',
      title: 'Cave Paintings',
      content: 'Early humans created the first physical data storage through cave paintings and petroglyphs.'
    },
    {
      year: '~3100 BCE',
      title: 'Clay Tablets & Cuneiform',
      content: 'Sumerians developed cuneiform writing on clay tablets for record-keeping and storytelling.'
    },
    {
      year: '~2500 BCE',
      title: 'Papyrus Scrolls',
      content: 'Ancient Egyptians used papyrus for administrative records and scientific data.'
    },
    {
      year: '1400s',
      title: 'Quipu Systems',
      content: 'Inca civilization used knotted strings (quipu) to encode numerical data and records.'
    },
    {
      year: '1440',
      title: 'Printing Revolution',
      content: 'Gutenberg\'s printing press enabled mass production and storage of written knowledge.'
    },
    {
      year: '1830s',
      title: 'Telegraph Era',
      content: 'Introduction of telegraphy marked the beginning of rapid data transmission.'
    },
    {
      year: '1930s',
      title: 'Early Computing',
      content: 'Punch cards and magnetic tape introduced digital data storage.'
    },
    {
      year: '1960s',
      title: 'Relational Model',
      content: 'Edgar F. Codd introduced the relational model, revolutionizing data organization.'
    },
    {
      year: '1970s',
      title: 'SQL Databases',
      content: 'Development of SQL and commercial relational database systems.'
    },
    {
      year: '2000s',
      title: 'NoSQL Revolution',
      content: 'Rise of NoSQL databases to handle unstructured and semi-structured data.'
    },
    {
      year: '2010s',
      title: 'Cloud & Big Data',
      content: 'Cloud databases and distributed systems enabled massive-scale data storage.'
    },
    {
      year: '2020s',
      title: 'AI-Native Storage',
      content: 'Vector databases emerge to power AI applications with semantic search capabilities.'
    }
  ];

  return (
    <Container maxWidth="lg">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography variant="h3" component="h1" gutterBottom color="primary">
          Database Evolution
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom color="textSecondary">
          From Cave Paintings to AI-Native Storage it has always been about language.
        </Typography>
        <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
          <Timeline position="alternate">
            {timelineItems.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  {index < timelineItems.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <motion.div
                    initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <Typography variant="h6" component="h3" color="primary">
                      {item.year}
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary">
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.content}
                    </Typography>
                  </motion.div>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Paper>
      </motion.div>
    </Container>
  );
};

export default History;
