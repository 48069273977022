import React, { useState } from 'react';
import { Container, Typography, Tabs, Tab, Box, Card, CardContent, Grid } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ComputerIcon from '@mui/icons-material/Computer';
import BusinessIcon from '@mui/icons-material/Business';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SchoolIcon from '@mui/icons-material/School';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import StorageIcon from '@mui/icons-material/Storage';

const Outcomes = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const industries = [
    {
      name: 'Healthcare',
      icon: <LocalHospitalIcon sx={{ fontSize: 40 }} />,
      outcomes: [
        {
          title: 'Enhanced Patient Care',
          description: 'Reduce diagnosis time by 40% through AI-powered analysis of medical records, imaging data, and research papers. Improve treatment accuracy with contextual patient history search.'
        },
        {
          title: 'Operational Efficiency',
          description: 'Cut administrative costs by 30% with automated document processing and intelligent routing. Reduce average patient wait times through optimized resource allocation.'
        },
        {
          title: 'Research & Development',
          description: 'Accelerate drug discovery by 50% through molecular structure analysis and research correlation. Enable real-time clinical trial matching with 90% accuracy.'
        },
        {
          title: 'Compliance & Safety',
          description: 'Ensure 100% regulatory compliance with automated audit trails and document verification. Reduce medical errors by 45% through intelligent alert systems.'
        }
      ]
    },
    {
      name: 'Financial Services',
      icon: <AccountBalanceIcon sx={{ fontSize: 40 }} />,
      outcomes: [
        {
          title: 'Risk Management',
          description: 'Reduce fraud losses by 60% using real-time pattern detection and anomaly identification. Improve credit risk assessment accuracy by 35% through comprehensive data analysis.'
        },
        {
          title: 'Customer Experience',
          description: 'Increase customer satisfaction by 40% with personalized banking experiences. Reduce customer service response times by 70% using intelligent document retrieval.'
        },
        {
          title: 'Investment Intelligence',
          description: 'Boost investment returns by 25% through AI-driven market analysis and pattern recognition. Enable real-time trading insights with semantic analysis of news and market data.'
        },
        {
          title: 'Operational Efficiency',
          description: 'Cut processing costs by 50% with automated document handling and compliance checking. Reduce manual data entry by 80% through intelligent form processing.'
        }
      ]
    },
    {
      name: 'E-commerce & Retail',
      icon: <ShoppingCartIcon sx={{ fontSize: 40 }} />,
      outcomes: [
        {
          title: 'Customer Experience',
          description: 'Increase conversion rates by 35% with personalized product recommendations. Reduce cart abandonment by 25% through intelligent search and discovery.'
        },
        {
          title: 'Inventory Management',
          description: 'Cut inventory costs by 30% with AI-driven demand forecasting. Improve stock accuracy to 99% through automated product categorization and tracking.'
        },
        {
          title: 'Marketing Optimization',
          description: 'Boost marketing ROI by 45% with targeted campaign optimization. Increase customer lifetime value by 50% through personalized engagement strategies.'
        },
        {
          title: 'Operational Efficiency',
          description: 'Reduce customer service costs by 40% with automated query resolution. Streamline supply chain operations with 30% improved efficiency.'
        }
      ]
    },
    {
      name: 'Technology',
      icon: <ComputerIcon sx={{ fontSize: 40 }} />,
      outcomes: [
        {
          title: 'Product Development',
          description: 'Accelerate development cycles by 40% with semantic code search and reuse. Reduce bugs by 60% through intelligent code analysis and pattern detection.'
        },
        {
          title: 'Customer Support',
          description: 'Cut support costs by 50% with AI-powered documentation search and automated responses. Improve first-contact resolution rates by 45%.'
        },
        {
          title: 'Data Intelligence',
          description: 'Increase data utilization by 70% through advanced analytics and insights. Enable real-time decision making with 90% accuracy in data correlation.'
        },
        {
          title: 'Innovation',
          description: 'Reduce time-to-market by 35% with AI-driven research and development. Improve product-market fit by 40% through semantic analysis of user feedback.'
        }
      ]
    },
    {
      name: 'Enterprise',
      icon: <BusinessIcon sx={{ fontSize: 40 }} />,
      outcomes: [
        {
          title: 'Knowledge Management',
          description: 'Reduce information retrieval time by 75% with semantic search across enterprise documents. Improve decision-making accuracy by 40% through comprehensive data access.'
        },
        {
          title: 'Process Automation',
          description: 'Cut operational costs by 45% through intelligent workflow automation. Increase employee productivity by 30% with AI-assisted task management.'
        },
        {
          title: 'Compliance & Risk',
          description: 'Ensure 99.9% compliance accuracy with automated monitoring and reporting. Reduce audit preparation time by 60% through intelligent document management.'
        },
        {
          title: 'Innovation',
          description: 'Accelerate digital transformation initiatives by 50% with AI-powered solutions. Enable data-driven strategy with 80% improved insight generation.'
        }
      ]
    },
    {
      name: 'Education',
      icon: <SchoolIcon sx={{ fontSize: 40 }} />,
      outcomes: [
        {
          title: 'Personalized Learning',
          description: 'Improve student engagement by 45% with AI-tailored learning paths. Increase completion rates by 30% through adaptive content delivery.'
        },
        {
          title: 'Research Efficiency',
          description: 'Accelerate research processes by 60% with semantic search across academic databases. Enable cross-disciplinary discoveries through intelligent content correlation.'
        },
        {
          title: 'Administrative Efficiency',
          description: 'Reduce administrative overhead by 40% through automated document processing. Cut response times to student queries by 70% with intelligent support systems.'
        },
        {
          title: 'Resource Optimization',
          description: 'Improve resource utilization by 35% with AI-driven scheduling and allocation. Reduce operational costs by 25% through intelligent facility management.'
        }
      ]
    },
    {
      name: 'Vertical AI Agents',
      icon: <SmartToyIcon sx={{ fontSize: 40 }} />,
      outcomes: [
        {
          title: 'Next SaaS Revolution',
          description: 'Similar to the $1T+ SaaS revolution, vertical AI agents represent a $100B+ opportunity across industries. Each vertical (healthcare, finance, retail) will spawn multiple billion-dollar AI-first companies.'
        },
        {
          title: 'Infrastructure Foundation',
          description: 'Vector databases like Weaviate serve as the critical infrastructure for AI agents, enabling real-time processing, multimodal support, and sophisticated RAG capabilities essential for vertical-specific solutions.'
        },
        {
          title: 'Vertical Automation',
          description: 'AI agents will revolutionize industries by automating complex workflows: medical billing ($100B+ market), legal document processing ($50B+ market), and financial compliance ($200B+ market).'
        },
        {
          title: 'Market Transformation',
          description: 'AI-native companies will be 10x larger than traditional SaaS counterparts. Early movers in vertical AI adoption will capture massive market share through superior automation and efficiency.'
        },
        {
          title: 'Developer Ecosystem',
          description: 'Open-source vector databases enable rapid prototyping and deployment of AI agents. Developers can build sophisticated vertical solutions using pre-built components and proven architectures.'
        },
        {
          title: 'Enterprise Evolution',
          description: 'Companies adopting vertical AI agents will significantly reduce operational costs while improving service quality. Early adopters will establish competitive advantages through AI-driven automation.'
        }
      ]
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Container maxWidth="lg">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Typography variant="h3" component="h1" gutterBottom color="primary">
          Business Outcomes
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom color="textSecondary" sx={{ mb: 4 }}>
          Transforming Industries with AI-Powered Vector Search & Vertical AI Agents
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="industry tabs"
          >
            {industries.map((industry, index) => (
              <Tab
                key={industry.name}
                icon={industry.icon}
                label={industry.name}
                sx={{
                  minHeight: 72,
                  '& .MuiTab-iconWrapper': {
                    mb: 1
                  }
                }}
              />
            ))}
          </Tabs>
        </Box>

        <Box sx={{ position: 'relative', minHeight: '60vh' }}>
          <AnimatePresence initial={false}>
            <motion.div
              key={selectedTab}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              style={{ position: 'absolute', width: '100%' }}
            >
              <Grid container spacing={3}>
                {industries[selectedTab].outcomes.map((outcome, index) => (
                  <Grid item xs={12} md={industries[selectedTab].name === 'Vertical AI Agents' ? 4 : 6} key={outcome.title}>
                    <motion.div
                      variants={itemVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ delay: index * 0.1 }}
                    >
                      <Card 
                        sx={{ 
                          height: '100%',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: 6
                          }
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6" component="h3" gutterBottom color="primary">
                            {outcome.title}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            {outcome.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </motion.div>
          </AnimatePresence>
        </Box>
      </motion.div>
    </Container>
  );
};

export default Outcomes;
